<template>
  <div class="selectfiles">
    <div class="nav_left_table_wrap" v-if="show">

      <div class="point_left"></div>
      <!-- :class="!show ? 'video_table_close' : ''" -->
      <div class="video_table">
        <!-- 标题 -->
        <div class="table_title">
          <div class="title">{{ type=='ppt' ? '请选择幻灯片' : '请选择word素材' }}</div>
          <i class="el-icon-close" @click="close"></i>
        </div>
        <div class="table_content">
          <div class="deletebox-l">
            <el-checkbox :indeterminate="isIndeterminate" v-model="checkedAll" @change="checkedAllOrClean"></el-checkbox>
            全选
          </div>
          <div class="ppt_list">
            <div class="contentbox scroll_bar" infinite-scroll-distance="2" v-infinite-scroll="load" v-loading="loading"
              element-loading-background="rgba(0, 0, 0, 0.1)">
              <el-checkbox-group v-model="checkList" @change="handleCheckedChange">
                <div class="item itemHover" v-for="(item, x) in list" :key="x">
                  <div class="item-pic">
                    <div class="picbox">
                      <el-image style="width: 240px; height: 134px" :src='item.thumbnail' fit="contain">
                        <div slot="error" class="image-slot">
                          <img src="@/assets/images/icon/img.png" alt="">
                        </div>
                      </el-image>
                    </div>
                    <!-- 右边的选中框 -->
                    <div class="selectCheck">
                      <el-checkbox :label="item.index"><br /></el-checkbox>
                    </div>
                    <!-- 下方的插入按钮 -->
                    <div class="insert">
                      <div class="button" @click="insertBtn(item)">插入画中画</div>
                    </div>
                  </div>
                </div>
              </el-checkbox-group>
            </div>
            <!-- 没有更多 -->
            <Loading :loading="loading" :list="list" :page="page" :totalPage="last_page">
            </Loading>
          </div>
          <div class="bottom-box">
            <el-button class="button">批量新增画中画场景</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    Loading: (resolve) => require(['@/components/loading/index'], resolve),
  },
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      show: false,
      checkedAll: false,
      loading: false,
      page: 0,
      last_page: 1, // 总页数
      limit: 20,
      list: [],
      checkList: [],
      isIndeterminate: true,
    }
  },
  methods: {
    open(files) {
      this.list = files
      this.show = true
    },
    close() {
      this.list = []
      this.show = false
    },
    // 全选
    checkedAllOrClean(val) {
      this.checkList = val ? this.list.map(item => item.index) : [];
      this.isIndeterminate = false;
    },
    // 加载更多
    load() {
      if (this.loading || this.page === this.last_page || this.last_page === 0) return
      this.page++
      this.getList()
    },
    // 列表
    getList() {

    },
    // 插入按钮
    insertBtn(item) {

    },
    handleCheckedChange(value) {
      let checkedCount = value.length;
      this.checkedAll = checkedCount === this.list.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.list.length;
    }
  }
}
</script>

<style lang="scss" scoped>
// 弹框最外层
.nav_left_table_wrap {
  position: absolute;
  left: 400px;
  top: 56px;
  height: calc(100% - 56px);
  z-index: 1;
  width: 340px;
  overflow: hidden;
  transition: width .2s linear;
  display: flex;
}

.point_left {
  position: absolute;
  top: 100px;
  left: 10px;
  z-index: 1;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-right: 10px solid #2B3036;
  border-left: 10px solid transparent;
  border-bottom: 10px solid transparent;

}

// 弹框头部
.video_table {
  position: absolute;
  top: 0;
  left: 30px;
  z-index: 1;
  // width: 290px;
  height: 100%;
  background: #2B3036;
  box-shadow: 10px 1px 11px 0px rgba(0, 0, 0, 0.34);
  transition: 0.3s;

  .table_title {
    width: 100%;
    height: 59px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #454A50;

    .title {
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #fff;
    }

    .el-icon-close {
      cursor: pointer;
      color: #fff;
    }
  }
}

// 关闭
.video_table_close {
  left: -400px;
}

// 内容区
.table_content {
  padding: 20px;
  height: calc(100vh - 59px);

  ::v-deep .el-button--primary {
    padding: 8px 20px;
    width: 100%;
  }
}

.deletebox-l {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
}

// ppt列表
.ppt_list {
  margin-top: 20px;

  .item {
    margin-bottom: 20px;

    .item-pic {
      width: 240px;
      height: 134px;
      // background: #000000;
      overflow: hidden;
      position: relative;
      cursor: pointer;

      .selectCheck {
        position: absolute;
        top: 6px;
        right: 0px;
        // opacity: 0;

      }

      .insert {
        position: absolute;
        left: 80px;
        bottom: 18px;
        transition: all .3s ease;
        opacity: 0;

        .button {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          width: 78px;
          height: 26px;
          background: linear-gradient(90deg, #4875FF 0%, #2B4AFE 100%);
          border-radius: 4px;
          text-align: center;
          line-height: 26px;
          cursor: pointer;
        }
      }
    }
  }

  .itemHover {

    &:hover {
      .item-pic {
        background: rgba(0, 0, 0, 0.5);
      }

      .selectCheck {
        opacity: 1;

      }

      .insert {
        opacity: 1;

      }
    }
  }
}

.contentbox {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 400px);
}

.bottom-box {
  width: 280px;
  height: 64px;
  background: #2B3036;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  padding: 16px 56px;
  .el-button {
    width: 168px;
    height: 32px;
    background: linear-gradient(90deg, #4875FF 0%, #2B4AFE 100%);
    border-radius: 4px;
    border: none;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 32px;
    text-align: center;
    padding: 0;
  }
}
</style>